<template>
  <div class="content">
    <ListingSidepanel
        v-if="listingPanelOpen"
        :listing-id="chosenListingId"
        :company-lander-page="true"
        @overlayClicked="closeListingPanel"
    />
    <ListContent
        :listings="listings"
        :active-filters="activeFilters"
        @openListingSidepanel="openListingSidepanel"
    />
  </div>
</template>

<script>
import ListContent from "@/components/CompanyListingsLander/ListContent";
import ListingSidepanel from "@/components/Listings/ListingSidepanel";
export default {
  name: "Content",
  components: {
    ListingSidepanel,
    ListContent,
  },
  props: [
    "activeFilters",
      "listings",
  ],
  watch: {
    filtersOpen: function () {
      this.filtersListOpen = this.filtersOpen;
    },
  },
  data() {
    return {
      filtersListOpen: this.filtersOpen,
      listingPanelOpen: false,
      chosenListingId: null,
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {

    usableListings(value) {
      this.$emit("listings", value);
    },
    closeListingPanel() {
      this.listingPanelOpen = false;
    },
    openListingSidepanel(listingId) {
      this.chosenListingId = listingId;
      this.listingPanelOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  padding: 0;
}
.content {
  display: block;
  padding-left: 104px;
  //padding-right: 88px;
  min-height: 980px;
  background-color: #f4f5f7;
}
.customer-row {
  margin-bottom: 8px;
  background-color: transparent;
}
.title {
  width: 100%;
}
img,
p {
  display: inline-block;
}
img {
  float: left;
}
.relation:hover {
  cursor: pointer;
  color: dodgerblue;
}
@media screen and (max-width: 520px) {
  .content {
    padding: 0 10px;
    background-color: #f4f5f7;
  }
}
</style>
