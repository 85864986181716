module.exports = {
  brokerId: null,
  priceMin: "",
  priceMax: "",
  floorMin: "",
  floorMax: "",
  areaMin: "",
  areaMax: "",
  roomMin: "",
  roomMax: "",
  dealTypes: [],
  estateTypes: [],
  conditions: [],
  months: [],
  statuses: [],
  lastUpdated: null,
  firstPublished: null,
  booked: false,
  balcony: false,
  parkingOption: false,
  roomsSeparate: false,
  storeRoom: false,
  sauna: false,
  county: null,
  city: null,
  cityParts: [],
  countyValue: null,
  cityValue: null,
};
