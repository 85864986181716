var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "customer-row"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.listings,
      expression: "!listings"
    }],
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }), _vm.listingsNone || _vm.usableListings && _vm.usableListings.length < 1 ? _c('div', [_vm.listingsNone ? _c('p', {
    staticClass: "heading-geo32 no-results-listing"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.listingsNone)) + " ")]) : _c('p', {
    staticClass: "heading-geo32 no-results-listing"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoResultsFound")) + " ")]), _vm.listingsNone === 'NoResultsFound' || !_vm.listingsNone && _vm.usableListings && _vm.usableListings.length < 1 ? _c('p', {
    staticClass: "clear-filters-button",
    on: {
      "click": _vm.clearFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ClearAllFilters")) + " ")]) : _vm._e()]) : _vm._e(), _c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.listingsNone && _vm.usableListings && _vm.usableListings.length > 0,
      expression: "!listingsNone && usableListings && usableListings.length > 0"
    }],
    staticClass: "listings-list"
  }, _vm._l(_vm.usableListings, function (listing) {
    return _c('v-col', {
      key: listing._id,
      staticClass: "listing-card",
      staticStyle: {
        "margin-top": "0px",
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('openListingSidepanel', listing._id);
        }
      }
    }, [_c('v-row', {
      staticClass: "image-row"
    }, [_c('img', {
      attrs: {
        "src": _vm.chooseCoverImage(listing.images, listing),
        "alt": ""
      }
    }), _c('v-row', {
      staticClass: "deal-estate-type-row"
    }, [_c('div', {
      staticClass: "deal-type-column"
    }, [_vm._v(" " + _vm._s(_vm.dealType(listing.dealType)) + " ")]), _c('div', {
      class: 'estate-type-column ' + listing.estateType
    }, [_vm._v(" " + _vm._s(_vm.estateType(listing.estateType)) + " ")])]), _c('v-row', {
      staticClass: "price-area-m2-row"
    }, [_c('div', {
      staticClass: "price-area-row"
    }, [listing.price ? _c('div', {
      staticClass: "price-column"
    }, [_vm._v(" " + _vm._s(_vm.formatAmount(listing.price)) + " ")]) : _vm._e(), !listing.price ? _c('div', {
      staticClass: "price-column"
    }, [_vm._v(" " + _vm._s('0 €') + " ")]) : _vm._e(), listing.generalSurfaceArea ? _c('div', {
      staticClass: "area-column"
    }, [_c('span', {
      staticStyle: {
        "margin": "0 0.5em"
      }
    }, [_vm._v("•")]), _vm._v(" " + _vm._s(listing.generalSurfaceArea) + " m"), _c('sup', [_vm._v("2")])]) : _vm._e()]), listing.pricePerSquareMeter ? _c('div', {
      staticClass: "price-m2-row"
    }, [_vm._v(" " + _vm._s(_vm.formatAmount(listing.pricePerSquareMeter)) + "/m"), _c('sup', [_vm._v("2")])]) : _vm._e(), !listing.pricePerSquareMeter ? _c('div', {
      staticClass: "price-m2-row"
    }, [_vm._v(" " + _vm._s('-') + "/m"), _c('sup', [_vm._v("2")])]) : _vm._e()])], 1), _c('div', {
      staticClass: "listing-basic-info-section"
    }, [_c('div', {
      staticClass: "listing-description-row"
    }, [listing.description ? _c('p', {
      staticClass: "listing-description"
    }, [_vm._v(" " + _vm._s(listing.description) + " ")]) : _vm._e()]), listing.listingName ? _c('div', {
      staticClass: "listing-name-row"
    }, [_c('p', {
      staticClass: "listing-name"
    }, [_vm._v(" " + _vm._s(listing.listingName) + " ")])]) : _vm._e(), _c('div', {
      staticClass: "listing-rooms-year-row"
    }, [listing.rooms > 1 ? _c('div', {
      staticClass: "listing-detail-col"
    }, [_vm._v(" " + _vm._s(listing.rooms) + " " + _vm._s(_vm.$t("rooms")) + " ")]) : _vm._e(), listing.rooms === 1 ? _c('div', {
      staticClass: "listing-detail-col"
    }, [_vm._v(" " + _vm._s(listing.rooms) + " " + _vm._s(_vm.$t("room")) + " ")]) : _vm._e(), listing.floor ? _c('div', {
      staticClass: "listing-detail-col"
    }, [listing.rooms ? _c('p', [_vm._v("•")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("Floor").toLowerCase()) + " " + _vm._s(listing.floor) + " ")]) : _vm._e(), listing.buildYear ? _c('div', {
      staticClass: "listing-detail-col"
    }, [listing.floor || listing.rooms ? _c('p', [_vm._v("•")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("BuildYear")) + " " + _vm._s(listing.buildYear) + " ")]) : _vm._e()])])], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }