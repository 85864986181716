<template>
  <div>
    <Header
        id="company-listings-lander-header"
        :filterConditions="filterConditions"
        @filtersOpen="filtersOpen"
        ref="header"
    />
    <Content
        :key="contentUpdater"
        v-if="listings"
        :listings="listings"
        id="company-listings-lander-content"
        @clear-filters="clearFiltersFromListings"
    />
  </div>
</template>

<script>
import Header from "@/components/CompanyListingsLander/Header"
import Content from "../../components/CompanyListingsLander/Content.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import nanoid from "nanoid";
import listingApi from "@/http/listing";

export default {
  name: "CompanyListingsLander",
  components: {
    Header,
    Content,
  },
  data() {
    return {
      companyListingsListingId: "",
      searchInput: "",
      colleagues: [
        {
          name: this.$t("TransferOwnership"),
          value: "allBrokers",
          _id: nanoid(16),
        },
      ],
      loaded: false,
      selectedBrokerId: null,
      dropdownUpdater: 0,
      filtersListOpen: false,
      position: {},
      offerListings: null,
      listings: null,
      contentUpdater: 0,
      filterConditions: [],
    };
  },
  async created() {
    this.initListingsProvisionalFilter();
    // this.filters.brokerId = this.colleagues[1]._id;
    await this.changeModalStateDeals(0);
    await this.getColleagues();
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "listingsLoaded",
      "provisionalFiltersUpdater",
      "provisionalFilters",
      "viewState",
      "filters",
    ]),
  },
  methods: {
    ...mapActions([
      "getListings",
      "changeModalStateDeals",
      "setActiveListingsView",
      "setProvisionalFilter",
      "setViewState",
      "changeProvisionalFilterCondition",
      "changeFilterCondition",
    ]),
    clearFiltersFromListings() {
      this.$refs.header.clearFilters()
    },
    initListingsProvisionalFilter() {
      const stateValue = this.viewState["listings"];
      const listingsFilters = this.filters["listings"];

      let savedViewId = this.$route.params.savedViewId;
      if (savedViewId) {
        this.filterConditions = listingsFilters.find(
            (dropdownItem) => dropdownItem._id === savedViewId
        ).conditions;
      } else if (stateValue) {
        this.filterConditions = this.provisionalFilters["listings"];
      } else {
        this.filterConditions = listingsFilters.find(
            (dropdownItem) => dropdownItem.isDefault
        ).conditions;
      }

      this.setProvisionalFilter({
        filterName: "listings",
        conditions: this.filterConditions,
      });
    },
    updateListing(event) {
      this.changedObject = event;
    },
    changeOffersSidepanelStatus(status) {
      this.offersSidepanelOpen = status;
    },
    setOffersAmount(value) {
      this.offersAmount = value;
    },
    async getColleagues() {
      let response = null;
      let companyId = this.$route.params.id
      response = await axios.get(`/api/public-users/${companyId}`);
      const users = response.data.brokers;
      users.forEach((user) => {
        this.colleagues.push(user);
      });
    },
    filtersOpen(value) {
      this.filtersListOpen = value;
    },
    firstElementDropdown() {
      return this.colleagues[0];
    },
    closePanel() {
      this.ownershipModal = false;
      this.firstElementDropdown();
      this.dropdownUpdater++;
    },
    changeSearch(val) {
      this.searchInput = val;
    },
    async initListings() {
      let response = null;
      const conditions = this.provisionalFilters.listings;
      const conditionsObj = conditions.reduce(
          (a, v) => ({ ...a, [v.name]: v.value }),
          {}
      );

      let companyId = this.$route.params.id
      conditionsObj.companyId = companyId

      response = await listingApi.getListingsFilteredPublic(conditionsObj);
      this.listings = response
      this.contentUpdater++;
    },
  },
  watch: {
    async provisionalFiltersUpdater() {
      await this.initListings();
    },
    "$route.query.notificationId": async function (val) {
      if (!val) return;
      await this.initListings();
    },
    async listingsLoaded(val) {
      if (val) {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss">
#listing-header,
#listing-content {
  transition: width, margin-left 300ms ease-in-out;
}

#listing-content {
  padding-bottom: 80px;
}

.slide-animation-open {
  margin-left: 256px !important;
}

.slide-animation-close {
  margin-left: 0 !important;
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7px;
  margin-left: 16px;
  margin-right: 16px;
}

.filter {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
  brightness(103%) contrast(102%);
}

</style>
