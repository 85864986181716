<template>
  <v-row class="customer-row">
    <div
        v-show="!listings"
        class="loader"
        style="position: absolute; top: 50%; left: 50%"
    ></div>

    <div v-if="listingsNone || (usableListings && usableListings.length < 1)">
      <p v-if="listingsNone" class="heading-geo32 no-results-listing">
        {{ $t(listingsNone) }}
      </p>
      <p v-else class="heading-geo32 no-results-listing">
        {{ $t("NoResultsFound") }}
      </p>
      <p
          class="clear-filters-button"
          @click="clearFilters"
          v-if="
          listingsNone === 'NoResultsFound' ||
          (!listingsNone && usableListings && usableListings.length < 1)
        "
      >
        {{ $t("ClearAllFilters") }}
      </p>
    </div>

    <v-row
        v-show="!listingsNone && usableListings && usableListings.length > 0"
        class="listings-list"
    >
      <v-col
          style="margin-top: 0px; cursor: pointer"
          v-for="(listing) in usableListings"
          :key="listing._id"
          class="listing-card"
          @click="$emit('openListingSidepanel', listing._id)"
      >
        <v-row class="image-row">
          <img
              :src="chooseCoverImage(listing.images, listing)"
              alt=""
          />
            <v-row class="deal-estate-type-row">
              <div class="deal-type-column">
                {{dealType(listing.dealType)}}
              </div>
              <div :class="'estate-type-column ' + listing.estateType">
                {{estateType(listing.estateType)}}
              </div>
            </v-row>
            <v-row class="price-area-m2-row">
              <div class="price-area-row">

                <div v-if="listing.price" class="price-column">
                  {{ formatAmount(listing.price)}}
                </div>
                <div v-if="!listing.price" class="price-column">
                  {{ '0 €' }}
                </div>

<!--               need to check if listing.landArea is correct property to use here-->
                <div v-if="listing.generalSurfaceArea" class="area-column">
                  <span style="margin: 0 0.5em">•</span>
                  {{listing.generalSurfaceArea}} m<sup>2</sup>
                </div>
<!--                <div v-if="!listing.generalSurfaceArea" class="area-column">-->
<!--                  <span style="margin: 0 0.5em">•</span>-->
<!--                  {{'-'}}-->
<!--                </div>-->
              </div>

              <div v-if="listing.pricePerSquareMeter" class="price-m2-row">
                {{ formatAmount(listing.pricePerSquareMeter) }}/m<sup>2</sup>
              </div>
              <div v-if="!listing.pricePerSquareMeter" class="price-m2-row">
                {{ '-' }}/m<sup>2</sup>
              </div>
            </v-row>
        </v-row>
        <div class="listing-basic-info-section">
          <div class="listing-description-row">
            <p v-if="listing.description" class="listing-description">
              {{ listing.description }}
            </p>
          </div>
          <div v-if="listing.listingName" class="listing-name-row">
            <p class="listing-name">
              {{ listing.listingName }}
            </p>
          </div>

          <div class="listing-rooms-year-row">
            <div v-if="listing.rooms > 1" class="listing-detail-col">
              {{ listing.rooms }} {{ $t("rooms") }}
            </div>
            <div v-if="listing.rooms === 1" class="listing-detail-col">
              {{ listing.rooms }} {{ $t("room") }}
            </div>
            <div v-if="listing.floor" class="listing-detail-col">
              <p v-if="listing.rooms">•</p>
              {{ $t("Floor").toLowerCase() }} {{listing.floor}}
            </div>
            <div v-if="listing.buildYear" class="listing-detail-col">
              <p v-if="listing.floor || listing.rooms" >•</p>
              {{ $t("BuildYear") }}  {{ listing.buildYear}}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";

export default {
  name: "ListContent",
  props: [
    "filtersOpen",
    "activeFilters",
    "changedObject",
    "listings",
  ],
  components: {
  },
  data() {
    return {
      focusedListing: null,
      isActive: null,
      statusesOpen: false,
      position: {},
      exactListing: null,
      sortCounters: [
        { price: 1, type: "number" },
        { generalSurfaceArea: 1, type: "number" },
        { rooms: 1, type: "number" },
        { dealType: 1, type: "string" },
        { estateType: 1, type: "string" },
        { listingName: 1, type: "string" },
        { city: 1, type: "string" },
        { status: 1, type: "string" },
        { broker: 1, type: "string" },
      ],
      originalArr: null,
      usableListings: null,
      filteredItems: null,
      filteredItemsCopy: null,
      selectedBar: null,
      toolBar: null,
      content: null,
    };
  },
  created() {
    this.setupListings();
    window.addEventListener("scroll", this.keepSelectedBarSticky);
    window.addEventListener("resize", this.keepSelectedBarSticky);
  },
  mounted() {
    this.toolBar = document.getElementById("toolbar-main");
    this.content = document.getElementById("listing-content");
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.keepSelectedBarSticky);
    window.removeEventListener("resize", this.keepSelectedBarSticky);
  },
  watch: {
    changedObject() {
      this.usableListings.forEach((listing) => {
        if (listing._id === this.changedObject.listingId) {
          listing.status = this.changedObject.status;
          listing.activeUntil = this.changedObject.activeUntil;
        }
      });
    },
    listingsUpdater: async function () {
      await this.getListings();
      await this.setupListings();
      this.$emit("listings", this.usableListings);
    },
    statusValues: function () {
      this.usableListings.forEach((listing) => {
        if (listing._id === this.statusValues.listingId) {
          listing.marketplaces = this.statusValues.marketplaces;
        }
      });
    },
    activeTab() {
      this.allListingsSelected = false;
    },
    filtersOpen: function () {
      if (this.selectedBar) {
        this.keepSelectedBarSticky();
      }
    },
    async listingsLoaded(val) {
      if (val) {
        await this.setupListings();
        this.highlightListing();
        this.$emit("listings", this.usableListings);
      }
    },
    search() {
      let searchValue = this.search.toLowerCase();
      let tempItemsAddress = [...this.originalArr];
      if (this.search != "" && this.search) {
        tempItemsAddress = tempItemsAddress.filter(
            (item) =>
                item.listingName.toLowerCase().indexOf(searchValue) > -1 ||
                item.description.toLowerCase().indexOf(searchValue) > -1
        );
        this.filteredItems = [...tempItemsAddress];
        this.filteredItemsCopy = [...this.filteredItems];
        if (this.filteredItems.length) {
          this.usableListings = this.filteredItems;
          this.sortCounters.forEach((counter) => {
            Object.keys(counter).forEach((key) => {
              if (
                  counter[key] !== "number" &&
                  counter[key] !== "string" &&
                  counter[key] !== 1
              ) {
                counter[key] = counter[key] - 1;
                let isnum = counter.type === "number";

                if (isnum) {
                  this.sortByNumericalValue(key);
                } else {
                  this.sortByStringValue(key);
                }
              }
            });
          });
        } else {
          this.usableListings = [];
        }
      } else if (this.search == "" || this.search == null) {
        this.usableListings = [...this.originalArr];
        this.sortCounters.forEach((counter) => {
          Object.keys(counter).forEach((key) => {
            if (
                counter[key] !== "number" &&
                counter[key] !== "string" &&
                counter[key] !== 1
            ) {
              counter[key] = counter[key] - 1;
              let isnum = counter.type === "number";
              if (isnum) {
                this.sortByNumericalValue(key);
              } else {
                this.sortByStringValue(key);
              }
            }
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "filteredListings",
      "listingsLoaded",
      "listingsNone",
      "listingsUpdater",
      "filters",
    ]),
  },
  methods: {
    ...mapActions([
      "getListings",
      "filterListingsPublic",
      "removeFiltersListings",
      "setProvisionalFilter",
    ]),
    dealType(dealType) {
      switch (dealType) {
        case "sale":
          return i18n.t("Sale");
        case "rent":
          return i18n.t("Rent");
        case "short_term_rent":
          return i18n.t("ShortTermRent");
        default:
          return "-";
      }
    },
    estateType(estateType) {
      switch (estateType) {
        case "allEstateTypes":
          return i18n.t("AllEstateTypes");
        case "commercial":
          return i18n.t("CommercialSpace");
        case "apartment":
          return i18n.t("Apartment");
        case "house":
          return i18n.t("House");
        case "house_part":
          return i18n.t("HousePart");
        case "land":
          return i18n.t("LandPlot");
        case "cottage":
          return i18n.t("SummerHouse");
        case "garage":
          return i18n.t("Garage");
      }
    },
    formatAmount(listingPrice) {
      const amount = listingPrice
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      let moneySign = "€";
      // switch (this.deal.currency) {
      //   case "EUR":
      //     moneySign = "€";
      //     break;
      //   case "USD":
      //     moneySign = "$";
      //     break;
      // }
      return amount + " " + moneySign;
    },
    chooseCoverImage(img, listing) {
      let coverImage = [];
      if (listing.isWatermarkApplied && listing.images[0]?.watermarkName) {
        img.forEach((image) => {
          if (!image.hidden && image.watermarkName) {
            coverImage.push(image.watermarkName);
          }
        });
      } else {
        img.forEach((image) => {
          if (!image.hidden && image.name) {
            coverImage.push(image.name);
          }
        });
      }
      return coverImage[0];
    },
    async clearFilters() {
      const clearConditions = JSON.parse(
          JSON.stringify(this.filters["listings"][0].conditions)
      );
      await this.setProvisionalFilter({
        filterName: "listings",
        conditions: clearConditions,
      });
      this.$parent.$emit('clear-filters')
    },
    highlightListing(listingId) {
      this.clearFocusedListing();

      let focusId = this.$route.query.notificationId;
      if (listingId) {
        focusId = listingId;
      }

      const interval = setInterval(() => {
        this.focusedListing = document.getElementById(
            "listing-header-row" + focusId
        );
        if (this.focusedListing) {
          this.focusedListing.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          this.focusedListing.style.border = "2px solid #FF5C01";
          document.documentElement.style.overflow = "visible";
        }
        clearInterval(interval);
      }, 100);
    },
    clearFocusedListing() {
      if (this.focusedListing) {
        this.focusedListing.style.border = "none";
      }
    },
    async openListing(listing) {
      this.clearFocusedListing();
      await this.$router.push({
        name: "CompanyListingsLanderDetail",
        params: { listingId: listing },
      });
    },
    async setupListings() {
      this.originalArr = [...this.listings];
      this.usableListings = this.listings;
    },
    formatDate(e) {
      var allDate = e.split(" ");
      var thisDate = allDate[0].split("-");
      var newDate = [thisDate[2], thisDate[1], thisDate[0]].join(".");
      return newDate;
    },
    keepSelectedBarSticky() {
      this.toolBar = document.getElementById("toolbar-main");
      this.selectedBar = document.getElementById("selected-listing-header");
      this.content = document.getElementById("listing-content");
      if (this.toolBar && this.toolBar.classList.contains("sticky")) {
        if (this.selectedBar) {
          this.content.style.marginTop =
              this.toolBar.offsetHeight + this.selectedBar.offsetHeight + "px";
          this.selectedBar.classList.add("sticky");
          this.selectedBar.style.marginTop = this.toolBar.offsetHeight + "px";
        } else {
          if(this.content){
            this.content.style.marginTop = this.toolBar.offsetHeight + "px";
          }
        }
      } else {
        if(this.content){
          this.content.style.marginTop = "0px";
          if (this.selectedBar) {
            this.selectedBar.classList.remove("sticky");
            this.selectedBar.style.marginTop = "0px";
          }
        }
      }
    },
    sortByStringValue(sortingField) {
      let sortingStep = null;
      let sortingCounterIndex = null;
      this.sortCounters.forEach((counter, index) => {
        Object.keys(counter).forEach((key) => {
          if (key === sortingField) {
            sortingCounterIndex = index;
            sortingStep = counter[key];
          } else if (key !== sortingField && key !== "type") {
            counter[key] = 1;
          }
        });
      });

      if (sortingStep == 3 && (this.search == null || this.search == "")) {
        this.usableListings = [...this.originalArr];
        this.sortCounters[sortingCounterIndex][sortingField] = 1;
      } else if (
          sortingStep == 3 &&
          (this.search != null || this.search != "")
      ) {
        this.usableListings = this.filteredItemsCopy;
        this.sortCounters[sortingCounterIndex][sortingField] = 1;
      } else if (sortingStep == 1) {
        if (sortingField == "estateType") {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          this.usableListings = this.usableListings.sort((a, b) => {
            let fa = this.formatEstateType(a[sortingField]).toLowerCase(),
                fb = this.formatEstateType(b[sortingField]).toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
        } else if (sortingField == "broker") {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          this.usableListings = this.usableListings.sort((a, b) => {
            let fa = a.creator.name.toLowerCase(),
                fb = b.creator.name.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
        } else {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          this.usableListings = this.usableListings.sort((a, b) => {
            let fa = a[sortingField].toLowerCase(),
                fb = b[sortingField].toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
        }
      } else if (sortingStep == 2) {
        if (sortingField == "estateType") {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          this.usableListings = this.usableListings.sort((a, b) => {
            let fa = this.formatEstateType(a[sortingField]).toLowerCase(),
                fb = this.formatEstateType(b[sortingField]).toLowerCase();

            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
            return 0;
          });
        } else if (sortingField == "broker") {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          this.usableListings = this.usableListings.sort((a, b) => {
            let fa = a.creator.name.toLowerCase(),
                fb = b.creator.name.toLowerCase();

            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
            return 0;
          });
        } else {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          this.usableListings = this.usableListings.sort((a, b) => {
            let fa = a[sortingField].toLowerCase(),
                fb = b[sortingField].toLowerCase();

            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
            return 0;
          });
        }
      }
    },
    sortByNumericalValue(sortingField) {
      let sortingStep = null;
      let sortingCounterIndex = null;
      this.sortCounters.forEach((counter, index) => {
        Object.keys(counter).forEach((key) => {
          if (key === sortingField) {
            sortingCounterIndex = index;
            sortingStep = counter[key];
          } else if (key !== sortingField && key !== "type") {
            counter[key] = 1;
          }
        });
      });

      if (sortingStep == 3 && (this.search == null || this.search == "")) {
        this.usableListings = [...this.originalArr];
        this.sortCounters[sortingCounterIndex][sortingField] = 1;
      } else if (
          sortingStep == 3 &&
          (this.search != null || this.search != "")
      ) {
        this.usableListings = this.filteredItemsCopy;
        this.sortCounters[sortingCounterIndex][sortingField] = 1;
      } else if (sortingStep == 1) {
        this.sortCounters[sortingCounterIndex][sortingField] += 1;
        const eitherSort = (arr = []) => {
          const sorter = (a, b) => {
            const landAreaSort = this.sortLandArea(sortingField, a, b, false);
            if (landAreaSort !== null) return landAreaSort;

            if (!a[sortingField]) return -1;
            if (!b[sortingField]) return 1;
            return +a[sortingField] - +b[sortingField];
          };
          arr.sort(sorter);
        };
        eitherSort(this.usableListings);
      } else if (sortingStep == 2) {
        this.sortCounters[sortingCounterIndex][sortingField] += 1;
        const eitherSort = (arr = []) => {
          const sorter = (a, b) => {
            const landAreaSort = this.sortLandArea(sortingField, a, b, true);
            if (landAreaSort !== null) return landAreaSort;

            if (!b[sortingField]) return -1;
            return +b[sortingField] - +a[sortingField];
          };
          arr.sort(sorter);
        };
        eitherSort(this.usableListings);
      }
    },
    sortLandArea(sortingField, a, b, reverse) {
      if (sortingField !== "generalSurfaceArea") return null;

      let aArea = a[sortingField];
      let bArea = b[sortingField];

      if (!aArea && a["estateType"] === "land" && a["landArea"]) {
        if (a["landArea"]["m2"]) {
          aArea = parseInt(a["landArea"]["m2"]);
        } else if (a["landArea"]["ha"]) {
          aArea = parseInt(a["landArea"]["ha"]) * 1000;
        }
      }

      if (!bArea && b["estateType"] === "land" && b["landArea"]) {
        if (b["landArea"]["m2"]) {
          bArea = parseInt(b["landArea"]["m2"]);
        } else if (b["landArea"]["ha"]) {
          bArea = parseInt(b["landArea"]["ha"]) * 1000;
        }
      }

      if (reverse) {
        if (!bArea) return -1;
        return +bArea - +aArea;
      } else {
        if (!aArea) return -1;
        if (!bArea) return 1;
        return +aArea - +bArea;
      }
    },
    formatListingType(e) {
      if (e == "sale") {
        return this.$t("Sale");
      } else if (e == "rent") {
        return this.$t("Rent");
      } else if (e == "short_term_rent") {
        return this.$t("ShortTermRent");
      } else {
        return "-";
      }
    },
    formatEstateType(e) {
      if (e == "commercial") {
        return this.$t("Commercial");
      } else if (e == "apartment") {
        return this.$t("Apartment");
      } else if (e == "house") {
        return this.$t("House");
      } else if (e == "house_part") {
        return this.$t("HousePart");
      } else if (e == "land") {
        return this.$t("LandPlot");
      } else if (e == "cottage") {
        return this.$t("SummerHouse");
      } else if (e == "garage") {
        return this.$t("Garage");
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../global_variables";
.col {
  padding: 0;
}

.content {
  display: block;
  padding-left: 104px;
  padding-right: 88px;
  min-height: 600px;
  background-color: #f4f5f7;
}
.no-results-listing {
  margin-top: 48px;
}
.clear-filters-button {
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #75787a;
  margin: 13px 0px;
}
.customer-row {
  margin-bottom: 8px;
  background-color: #f4f5f7 !important;
  min-width: 360px;
  padding-top: 24px;
}
.title {
  width: 100%;
}
img,
p {
  display: inline-block;
}

img {
  float: left;
}
.listings-list {
  flex-direction: row;
  gap: 32px;
}
.image-row {
  justify-self: center;
  position: relative;
  //width: 250px;
  height: 210px;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Inter';
  font-style: normal;
  color: #FFFFFF;
  background-color: #e8eaec;
}
.image-row img {
  position: absolute;
  left: 45%;
  top: 50%;
  height: 104%;
  width: auto;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  -ms-transform: translate(-45%,-50%);
  transform: translate(-45%,-50%);
}
.deal-estate-type-row {
  z-index: 2;
  margin-left: 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  max-height: 24px;
  gap: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.deal-type-column {
  width: fit-content;
  padding: 4px 8px;
  background: #000000;
  border-radius: 4px;
}
.estate-type-column {
  //width: 79px;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-flex;
}
.apartment {
  background: #16AFB9;
}
.house {
  background: #FF5C01;
}
.house_part {
  background: #FF5C01;
}
.commercial {
  background: #F81CA0;
}
.cottage {
  background: #FF5C01;
}
.land {
  background: #7200E2;
}
.garage {
  background: #A89963;
}
.price-area-m2-row {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 52px;
  width: 216px;
  background: lightgrey;
  margin: 153px 88px 16px 16px;
  padding: 8px 16px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.price-area-row {
  display: flex;
  flex-direction: row;
}
.price-m2-row {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.listing-basic-info-section {
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #000000;
  font-family: 'Inter';
  font-style: normal;
}
.listing-name-row {
  margin-bottom: 16px;
}
.listing-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90%;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.listing-description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90%;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.listing-rooms-year-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
  padding: 8px;
  height: 32px;
  background: #F4F5F7;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.listing-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03), 0px 0px 1px rgba(0, 0, 0, 0.04);
  min-width: 320px;
  max-width: 320px;
  min-height: 337px;
  max-height: 337px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  background-color: white;
  &:hover {
    background: $light-grey;
  }
  .no-image {
    width: 100%;
    height: 210px;
    border-radius: 8px 8px 0 0;
    background-color: #f4f5f7;
  }
}

.listing-basic-info-section:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.listing-item {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  overflow: hidden;
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.white-background {
  background-color: #ffffff;
}
#listing-header,
#listing-content {
  transition: width, margin-left 300ms ease-in-out;
}

#listing-content {
  padding-bottom: 12px;
}

.slide-animation {
  margin-left: 256px !important;
}

.slide-animation-close {
  margin-left: 0 !important;
}
.hidden {
  display: none;
}
.normal-weight {
  font-weight: 400;
}

.bold-weight {
  font-weight: 700;
}
@media screen and (max-width: 520px) {
  .listing-card {
    display: flex;
    flex-direction: column;
    min-width: 220px;
    max-width: 320px;
  }
  .listings-list{
    gap: 32px;
    max-width: 320px;
  }
  .customer-row {
    display: flex;
    justify-content: center;
  }
}
</style>
