<template>
  <div class="header" id="listing-header" style="background-color: white">
    <v-row class="title-row">
      <v-col>
        <div class="header-logo"
        >
  <!--        Get logo from company configuration? -->
          <img
              src="../../assets/images/rpFullLogo.svg"
              alt=""
          />
        </div>
      </v-col>
  <LanguageDropDown style="z-index: 4"/>
    </v-row>

    <HeaderToolbar
        v-if="loaded"
        :module="'listing'"
        :top-margin="toolbarTopMargin"
    >
      <div style="display: flex; width: 100%; flex-wrap: wrap; gap: 8px">
        <div style="margin-left: 0px">
          <v-text-field
              autocomplete="off"
              outlined
              hide-details
              :id="'search-bar'"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('Search') + '...'"
              style="background: white"
              v-model="search"
          >
          </v-text-field>
        </div>
        <DropdownMulti
            :items="dealTypes"
            v-model="selectedDeals"
            :display-key="'name'"
            :value-key="'value'"
            :height="'34px'"
            :width="'160px'"
            :font-size="'12px'"
            :default-value="$t('AllListings')"
        />
        <DropdownMulti
            :items="estateTypes"
            v-model="selectedEstates"
            :display-key="'name'"
            :value-key="'value'"
            :height="'34px'"
            :width="'140px'"
            :font-size="'12px'"
            :default-value="$t('AllEstateTypes')"
        />
        <Dropdown
            :key="'colleagues-' + toolbarUpdater"
            :items="colleagues"
            :display-key="'name'"
            :value-key="'_id'"
            :default-item="colleagues[0]"
            :selected="selectedUser"
            @itemSelected="getBrokerListings"
        />
        <div
            @click="openExtraFilters"
            style="
            cursor: pointer;
            margin-left: 12px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
          "
        >
          <p style="align-self: center" class="link-small">
            {{ $t("AdvancedFilters") }}
          </p>
          <img
              style="
              width: 16px;
              height: 16px;
              margin-left: 8px;
              cursor: pointer;
              align-self: center;
            "
              src="../../assets/images/chevron.svg"
              alt=""
              v-bind:class="{
              up: filtersOpen,
              down: !filtersOpen,
            }"
          />
        </div>
      </div>
      <hr />
      <AdvancedFilters
          :key="'advanced-filters-' + toolbarUpdater"
          :filter-data="filterData"
          :chosen-broker="chosenBrokerId"
          :deal-types="selectedDealTypes"
          :estate-types="selectedEstateTypes"
          @filtersReset="clearFilters"
          v-show="filtersOpen"
      ></AdvancedFilters>
    </HeaderToolbar>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import HeaderToolbar from "@/components/common/HeaderToolbar";
import Dropdown from "@/components/common/Dropdown";
import DropdownMulti from "@/components/common/DropdownMulti";
import AdvancedFilters from "@/components/Listings/AdvancedFilters";
import nanoid from "nanoid";
import LanguageDropDown from "../common/LanguageDropdown";
const filtersObject = require("@/helpers/data/listings/filters");
export default {
  name: "CompanyListingsLanderHeader",
  props: ["filterConditions"],
  components: {
    LanguageDropDown,
    HeaderToolbar,
    Dropdown,
    DropdownMulti,
    AdvancedFilters,
  },
  data() {
    return {
      loaded: false,
      selectedUser: null,
      toolbarUpdater: 0,
      colleagueUpdater: 0,
      chosenBrokerId: null,
      filtersOpen: false,
      dropdownOpen: false,
      isLeadsOpen: false,
      search: "",
      highlightDone: false,
      colleaguesPlaceholderId: nanoid(16),
      colleagues: [
        { name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers" },
      ],
      dealTypes: [
        { name: this.$t("Sale"), value: "sale" },
        { name: this.$t("Rent"), value: "rent" },
        {
          name: this.$t("ShortTermRent"),
          value: "short_term_rent",
        },
      ],
      estateTypes: [
        {
          name: this.$t("CommercialSpace"),
          value: "commercial",
        },
        { name: this.$t("Apartment"), value: "apartment" },
        { name: this.$t("House"), value: "house" },
        { name: this.$t("HousePart"), value: "house_part" },
        { name: this.$t("LandPlot"), value: "land" },
        { name: this.$t("SummerHouse"), value: "cottage" },
        { name: this.$t("Garage"), value: "garage" },
      ],
      selectedDealTypes: null,
      selectedEstateTypes: null,
      setupDone: false,
      filterData: null,
      selectedDeals: [],
      selectedEstates: []
    };
  },
  async created() {
    this.setup();
  },
  mounted() {
  },
  watch: {
    search: async function (val) {
      await this.filter({
        filterName: "listings",
        conditionName: "search",
        value: val,
        valueTitle: val,
      });
    },
    "$route.query.notificationId": function () {
      this.showAllBrokersListings();
    },
    filtersOpen: function () {
      this.$emit("filtersOpen", this.filtersOpen);
    },
    provisionalFiltersUpdater: function () {
      this.refreshToolBar();
    },
    selectedDeals: {
      handler(newVal) {
        let dealArray = [];
        newVal.forEach((deal) => {
          dealArray.push(deal.value);
        });
        this.filter({
          filterName: "listings",
          conditionName: "dealTypes",
          value: dealArray,
          valueTitle: dealArray.join(", "),
        });
      },
      deep: true,
    },
    selectedEstates: {
      handler(newVal) {
        let estateArray = [];
        newVal.forEach((estate) => {
          estateArray.push(estate.value);
        });
        this.filter({
          filterName: "listings",
          conditionName: "estateTypes",
          value: estateArray,
          valueTitle: estateArray.join(", "),
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "listingsFiltersReset",
      "listingsLoaded",
      "filters",
      "provisionalFilters",
      "provisionalFiltersUpdater"
    ]),
    toolbarTopMargin() {
      if (this.filtersOpen) {
        return "400px";
      } else {
        return null;
      }
    },
    colleagueDefault() {
        return this.colleagues[0];
    },
  },
  methods: {
    ...mapActions([
      "filterListings",
      "filter",
      "setProvisionalFilter",
      "changeProvisionalFilterCondition",
    ]),
    async setup() {
      await this.getColleagues();
      this.initFilters();
      this.loaded = true;
    },
    initFilters() {
      this.filterByConditions(this.filterConditions);
    },
    setEmptyFiltersData() {
      this.search = "";
      this.dealTypes.forEach(dealType => dealType.selected = false);
      this.estateTypes.forEach(estateType => estateType.selected = false);
      this.filterData = JSON.parse(JSON.stringify(filtersObject));
      this.selectedUser = this.colleagues[0];
    },
    filterByConditions(conditions) {
      this.setEmptyFiltersData();
      conditions.forEach((condition) => {
        if (!condition.value) return true;
        switch (condition.name) {
          case "search":
            this.search = condition.value;
            break;
          case "dealTypes":
            condition.value.forEach((filterDealType) => {
              this.dealTypes.forEach((dealType) => {
                if (filterDealType === dealType.value) {
                  dealType.selected = true;
                }
              });
            });
            break;
          case "estateTypes":
            condition.value.forEach((filterEstateTypes) => {
              this.estateTypes.forEach((estateType) => {
                if (filterEstateTypes === estateType.value) {
                  estateType.selected = true;
                }
              });
            });
            break;
          case "conditions":
            condition.value.forEach(status => {
              this.filterData.conditions.push({ value: status });
            });
            break;
          case "months":
            condition.value.forEach(month => {
              this.filterData.months.push({ value: month });
            });
            break;
          case "statuses":
            condition.value.forEach(status => {
              this.filterData.statuses.push({ value: status });
            });
            break;
          case "brokerId":
            this.colleagues.forEach((colleague) => {
              if (colleague._id === condition.value) {
                this.selectedUser = colleague;
              }
            });
            break;
          case "cityPart":
            condition.value.forEach(cityPart => {
              this.filterData.cityParts.push(cityPart);
            });
            break;
          default:
            this.filterData[condition.name] = condition.value;
        }
      });
    },
    async setSearch(value) {
      await this.filter({
        filterName: "listings",
        conditionName: "search",
        value: value,
        valueTitle: value,
      });
    },
    async showAllBrokersListings() {
      this.colleagueUpdater++;
    },
    openExtraFilters() {
      this.filtersOpen = !this.filtersOpen;
    },
    async filterByDealType(dealTypes) {
      await this.filter({
        filterName: "listings",
        conditionName: "dealTypes",
        value: dealTypes.map((dealType) => dealType.value),
        valueTitle: dealTypes.map((dealType) => dealType.value),
      });
    },
    async filterByEstateType(estateTypes) {
      await this.filter({
        filterName: "listings",
        conditionName: "estateTypes",
        value: estateTypes.map((dealType) => dealType.value),
        valueTitle: estateTypes.map((dealType) => dealType.value),
      });
    },
    async getBrokerListings(selectedItem) {
      await this.filter({
        filterName: "listings",
        conditionName: "brokerId",
        value: selectedItem.value === "allBrokers" ? 0 : selectedItem._id,
        valueTitle: selectedItem.value === "allBrokers" ? "allBrokers" : selectedItem.name,
      });
    },
    async getCompanyInfo() {
      const companyId = this.$route.params.id
      const response = await axios.get(`/api/company/${companyId}`);
      this.company = response.data;
    },
    async getColleagues() {
      let response = null;
      let companyId = this.$route.params.id
      response = await axios.get(`/api/public-users/${companyId}`);
      const users = response.data.brokers;
      users.forEach((user) => {
        this.colleagues.push(user)
      });
      if (this.$route.query.notificationId) {
        await this.showAllBrokersListings();
      } else {
        this.toolbarUpdater++;
      }
      this.setupDone = true;
    },
    async clearFilters() {
      const clearConditions = JSON.parse(JSON.stringify(this.filters["listings"][0].conditions));
      await this.setProvisionalFilter({
        filterName: "listings",
        conditions: clearConditions
      });
      this.toolbarUpdater++
    },
    refreshToolBar() {
      const conditions = this.provisionalFilters["listings"];
      this.filterByConditions(conditions);
      if (this.dropdownOpen) {
        this.toolbarUpdater++;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.no-active {
  width: 270px;
}

.tab-row {
  padding-left: 104px;
  margin-top: 0px;
}

.tab-selected {
  padding-bottom: 24px;
  font-weight: 600;
  border-bottom: 2px solid black;
  cursor: pointer;
}

.tab-unselected {
  padding-bottom: 24px;
  cursor: pointer;
}

.col {
  padding: 0;
}
.left-row {
  justify-content: flex-start;
  .v-select {
    max-width: 250px;
  }
  .sector-type {
    margin-right: 42px;
  }
}
.right-row {
  justify-content: flex-end;
  .autocomplete {
    max-width: 300px;
    margin-right: 16px;
  }
  .icon-button {
    border: 1px solid #e4e4e4;
    height: 40px;
    width: 40px;
    display: flex;
    outline: none;
    padding: 16px;
    align-items: center;
    user-select: none;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 8px;

    justify-content: center;
  }
}
.filter-menu {
}
.filter-wrapper {
  padding: 22px 128px;
  background-color: white;
  flex-flow: wrap;

  display: flex;
  gap: 26px;
}
.v-menu__content {
  max-width: 100% !important;
}
.columns-wrapper {
  display: flex;
  max-width: 100%;
}
.right-column {
  margin-left: 26px;
}
.header-logo {
  min-width: 248px;
  height: 56px;
  padding-left: 24px;
}
.filter-row {
  flex-flow: nowrap;
  gap: 27px;
  align-items: baseline;
  .selects {
    gap: 20px;
  }
}
.select-filter {
  max-width: 300px;
}
.select-filter.v-text-field--outlined ::v-deep fieldset {
  border: none !important;
  background-color: #f4f5f7;
}
#detailView {
  padding-bottom: 22px;
}
#boardView {
  margin-left: 24px;
  padding-bottom: 22px;
}
#detailView,
#boardView {
  cursor: pointer;
}
.active-view {
  border-bottom: 1px solid black;
}
hr {
  flex-basis: 100%;
  height: 0;
  margin: 0;
  border: 0;
}
::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}
::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}
::v-deep .v-icon.v-icon {
  font-size: 18px;
}
::v-deep .theme--light.v-icon {
  color: black;
}
::v-deep .v-text-field__slot input {
  font: revert !important;
}
.up {
  display: initial;
}
.down {
  display: initial;
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
</style>
