var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    ref: "header",
    attrs: {
      "id": "company-listings-lander-header",
      "filterConditions": _vm.filterConditions
    },
    on: {
      "filtersOpen": _vm.filtersOpen
    }
  }), _vm.listings ? _c('Content', {
    key: _vm.contentUpdater,
    attrs: {
      "listings": _vm.listings,
      "id": "company-listings-lander-content"
    },
    on: {
      "clear-filters": _vm.clearFiltersFromListings
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }