var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_vm.listingPanelOpen ? _c('ListingSidepanel', {
    attrs: {
      "listing-id": _vm.chosenListingId,
      "company-lander-page": true
    },
    on: {
      "overlayClicked": _vm.closeListingPanel
    }
  }) : _vm._e(), _c('ListContent', {
    attrs: {
      "listings": _vm.listings,
      "active-filters": _vm.activeFilters
    },
    on: {
      "openListingSidepanel": _vm.openListingSidepanel
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }